import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Backdrop, Grid } from '@material-ui/core';

import { ContactForm } from './index';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0rem',
		minHeight: '98vh',
		maxHeight: '98vh',
		maxWidth: '100%',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		display: 'none',
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		borderRadius: '20px',
	},
	closeIcon: {
		color: theme.white,
		fontSize: 45,
		margin: '1rem 2rem 0 1rem',
		position: 'relative',
		bottom: '20px',
		transition: 'all .3s ease-out',
		opacity: '0.8',
		'&:hover': {
			cursor: 'pointer',
			opacity: '1',
			transform: 'rotate(180deg) ',
		},
		[theme.breakpoints.down('md')]: {
			margin: '1rem 1rem 0 1rem',
		},
	},
}));

//every tab change triggers a rerender of the whole component. memoized this component to avoid rendering  multiple marketo forms on same page
const MemoizedForm = memo(ContactForm);

export const ContactFormModal = memo(
	({
		open,
		toggleOpen,
		baseUrl,
		munchkinId,
		formId,
		contactForm,
		callback,
		formImages,
		location,
		pardotUrl,
	}) => {
		const classes = useStyles();

		return (
			<div>
				<Dialog
					aria-labelledby='spring-modal-title'
					aria-describedby='spring-modal-description'
					className={classes.modal}
					open={open}
					onClose={(e) => toggleOpen(false)}
					BackdropComponent={Backdrop}
					BackdropProps={{
						style: { backgroundColor: '#000', opacity: '0.80' },
					}}
					PaperProps={{
						style: {
							backgroundColor: 'transparent',
							boxShadow: 'none',
							margin: '0',
							maxWidth: '100%',
						},
					}}>
					<Grid container item direction='row' justify='flex-end'>
						<FontAwesomeIcon
							icon={['fas', 'xmark']}
							size='1x'
							className={classes.closeIcon}
							onClick={(e) => toggleOpen(false)}
						/>
					</Grid>
					<MemoizedForm
						modal={true}
						baseUrl={baseUrl}
						munchkinId={munchkinId}
						formId={formId}
						contactForm={contactForm}
						callback={callback}
						formImages={formImages}
						location={location}
						pardotUrl={pardotUrl}
					/>
				</Dialog>
			</div>
		);
	}
);
